@import './variable.scss';

$theme-colors: (
    "primary": $primary,
);

.pg4-card {
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    // box-shadow: $shadow;
    padding: 20px;
    border-radius: $border-radius;
    margin-top: 10px;
    transition: all .2s ease-in-out;
    border: 2px solid $border-color;
}

.pg4-btn {
    position: relative;
    overflow: hidden;
    outline: none;
    border: none;
    border-radius: 4px !important;
    font-weight: 900;
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    line-height: 32px;
    min-height: 40px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all .2s;
    padding: 5px 10px;


    &:disabled {
        background: $secondary;
        cursor: not-allowed;
        opacity: 0.7;
    }

    &.sm-full {
        width: 100% !important;
    }

    &_primary {
        background: $primary;
        color: #fff;

        &:disabled {
            background: $secondary;
            cursor: not-allowed;
        }

        &:hover {
            background: #0b1d53 !important;
        }

        &:focus {
            border: 2px solid #000;

        }
    }

    &_outline {
        border: 2px solid $primary;
        background: transparent;
        color: $primary !important;

        &:disabled {
            border: 2px solid $secondary;
            background: $secondary;
            color: #0b1d5380;
            cursor: not-allowed;
        }

        &:hover {
            background: $primary !important;
            color: #fff !important;
        }

        &:focus {
            border: 2px solid #000;

        }


    }

    &_link {
        background: transparent;
        text-decoration: none;
        color: $primary !important;
        letter-spacing: 1px;
        cursor: pointer;
        padding: 0;

        &:disabled {
            background: $secondary;
            color: #0b1d5380;
            cursor: not-allowed;
        }

        &:hover {
            color: #0b1d53 !important;
            background: $secondary;
        }

        &:focus {
            border: 2px solid #000;

        }
    }
}





.msq-input[type="number"] {
    width: 70px;
}



.msq {
    &-multi_radio {
        // border-radius: 8px;
        // padding: 5px 10px;
        transition: all .2s ease-in-out;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        border: 2px solid $primary;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 50px;
        width: 50px;

        &__selected {
            background: $primary;
            color: #fff !important;
        }

        &.disabled {
            // background: $primary;
            border: 2px solid $tertiary;
        }
    }

    &-radio {
        border-radius: 8px;
        border: 2px solid $primary;
        padding: 8px 14px;
        transition: all .2s ease-in-out;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;

        &__selected {
            background: var(--pg_primary__p);
            color: #fff !important;
        }
    }

    &-input {
        border: none;
        outline: none;
        height: 45px;
        background-color: #cccccc80;
        border-radius: 8px;
        padding: 5px 10px;

        &[type="number"] {

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    &-progress {
        background: #d4d4d4;
        height: 8px;
        position: relative;
        border-radius: 4px;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

        &::after {
            border-radius: 4px 4px;
            content: "";
            color: var(--pg_primary__p);
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: var(--progress-width);
            transition: all .9s ease-in-out;
            background-color: var(--pg_primary__p);
            box-shadow: inset rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, inset rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

        }
    }
}

// 
.msq-section {
    &__item {
        color: $primary;
        display: flex;
        justify-content: space-between;
        margin: 10px 10px;
        padding: 5px 10px;
        // cursor: default;
        border-radius: $border-radius;

    }
}


@media screen and (max-width: 950px) {
    .w-sm-100 {
        width: 100% !important;
    }
}


.center .date {
    font-size: 30px;
    font-weight: 300;
}

.center .date-picker {
    display: flex;
    padding: 50px 20px;
    margin: 30px 0;
    overflow: hidden;
    width: 100%;
}

.center .day,
.center .month,
.center .year {
    position: relative;
    height: 50px;
    margin: 0px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 0;
}

.center .year:before,
.center .year:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #fff;
    opacity: 0.8;
    pointer-events: none;
    z-index: 1;
}

.center .day:before,
.center .month:before,
.center .year:before {
    top: -51px;
}

.center .day:after,
.center .month:after,
.center .year:after {
    bottom: -51px;
}

.center .day li,
.center .month li,
.center .year li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.stt-fab {
    font-size: 10px;
    font-family: Mulish;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    position: fixed;
    bottom: 2%;
    right: 1%;
    transform: translateY(-50%);
    padding: 8px;
    /* Add padding to control size */
    border-radius: 50%;
    background-color: var(--pg_primary__p);
    word-break: break-word;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    z-index: 999;
    box-shadow: var(--shadow);
    /* Assuming you have a shadow variable */
    line-height: 1.2;
    aspect-ratio: 1 / 1;
    /* Keeps it a perfect circle */
    /* Adjusts line height for better text readability */

    /* Mobile View */
    @media (max-width: 768px) {
        height: 55px;
        width: 55px;
        line-height: 1.2;
        font-size: 10px;
        padding: 0;
        /* To avoid oversized padding on small screens */
    }
}


// mobile
.pg4-section {
    position: absolute;
    top: 132px;
    padding: 20px 0px;
    background: #ffff;
    background: var(--pg_light_grey);
    // border: 2px solid var(--pg_primary__p);
    width: 100%;
    left: 0;
    // border-radius: 8px 8px;
    transition: all .1s ease-in-out;
    visibility: hidden;
    animation: scaleAnimation 0.2s ease-in-out forwards;
    user-select: none;

    &__item {
        padding: 10px 10px;
    }

    &.show {
        visibility: visible;
        animation-name: scaleAnimation;
        transform-origin: top center;
    }
}

.pg4-bottom-nav {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    border-top: 2px solid var(--pg_primary__p);
    background-color: #fff;
    padding: 10px;

    &__btn {
        height: 40px;
        width: 100px;
        border-radius: 8px;
        border: 2px solid $primary;
        color: $primary;
        padding: 5px 10px;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;

        &:disabled {
            border: 2px solid $secondary;
            background: $secondary;
            color: #0b1d5380;
            cursor: not-allowed;
        }
    }

    &__text {
        font-size: 14px;
        color: var(--pg_primary__p);
        cursor: pointer;
        font-weight: bold;
    }
}



@keyframes scaleAnimation {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }

    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}

.pg4-select {
    // appearance: none;
    // padding: 10px 20px;
    width: 80px;
    height: 40px;
    box-shadow: $shadow;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $primary
            /* Color of the scrollbar thumb on hover */
    }

    scrollbar-width: thin;
    scrollbar-color: #888 #f5f5f5;
}

.focus-card {
    border: 2px solid #a0add0;
    padding: 10px;
    border-radius: 4px;
    font-family: Mulish;
    font-weight: 700;

    &__title {
        font-size: 20px;
        font-weight: 400;
        font-family: 'Varela Round', sans-serif;
    }

    &__score {
        text-transform: uppercase;
        margin: 20px 0 20px 5px;
        line-height: 22px;

        span {
            border-radius: 4px;
            padding: 5px 8px;
            font-size: 12px;
            font-weight: 700;
        }

        span[data-score="ELEVATED"] {
            background-color: #ec1a3f;
            color: white;
        }

        span[data-score="ACTION"] {
            background-color: #f3bb1c;
            color: white;
        }

        span[data-score="GOOD"] {
            background-color: #2471c1;
            color: white;
        }
    }

    &__impact {
        margin: 20px 0 20px 5px;
        text-transform: uppercase;
        font-weight: 700;

        span {
            background-color: #2471c1;
            color: white;
            border-radius: 4px;
            padding: 5px 8px;
            font-size: 12px;
            font-weight: 700;
        }
    }

    &__btn {
        padding: 2px 8px !important;
        font-size: 12px !important;
    }
}

.patient_notes {
    border: none;
    background: #f6f6f6;
    width: 100%;
    resize: none;
    padding: 10px;
}

.pill,
.circle {
    border-radius: 30px;
    padding: 5px 10px;
    color: white;
    text-transform: uppercase;
    margin: 1px;
    font-size: 10px;
    font-weight: 700;
    height: 26px;
    font-family: Muli, sans-serif;
    line-height: 1.8;
    letter-spacing: .8px;

    &-sleep {
        background: #201160;
    }

    &-mental_health_and_mood {
        background: #d2004c;
    }

    &-mental {
        background: #d2004c;
    }

    &-digestive_health {
        background: #61a60d;
    }

    &-digestive {
        background: #61a60d;
    }

    &-energy_and_fatigue {
        background: #00a09b;
    }

    &-energy {
        background: #00a09b;
    }

    &-metabolic_health {
        background: #bcb629;
    }

    &-metabolic {
        background: #bcb629;
    }

    &-immune_health {
        background: #ff9e18;
    }

    &-immune {
        background: #ff9e18;
    }

    &-cognitive {
        background: #7373c0;
    }

    &-musculoskeletal {
        background: #00a7e1;
    }

    &-hormones {
        background: #005c5d;
    }

    &-general {
        background: #bcb629;
    }

    &-anxiety {
        background: #d2004c;
    }

    &-mood {
        background: #d2004c;
    }

    &-stress {
        background: #d2004c;
    }

    @media screen and (max-width:600px) {
        font-size: 10px;
    }
}

.circle {
    display: inline-block;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    padding: 8px;
    border: 1px solid #fff;

    &:nth-of-type(2) {
        margin-left: -10px;
    }

    &:nth-of-type(3) {
        margin-left: -10px;
    }
}

.report_nav-card {
    min-height: 300px;
}

/* Fade-in animation */
.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all .2s ease-in-out;
}

/* Fade-out animation */
.fade-exit {
    opacity: 1;
    transform: translateY(0);
    transition-delay: var(--fade-exit) !important;
}

.fade-exit-active {
    opacity: 0;
    transition: all .2s ease-in-out;
    transition-delay: var(--fade-exit) !important;
}

.dashboard-report-table__popover-content div {
    @media (max-width: 768px) {
        left: 55% !important;
    }
}

.pg4-table {
    @media (max-width: 608px) {

        thead tr {
            font-size: 12px;
        }
    }
}


.pl_button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    button {
        flex: 1 1 300px;
    }
}

[role="tooltip"].psqi-popup-content {
    width: 450px !important;
}

.msq-response-table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    max-width: 100%;
    padding-bottom: 1rem;
}

.msq-response-table {
    table-layout: fixed;
    width: 100%;
    min-width: 800px;

    th {
        color: $primary !important;
        font-weight: 800;
    }

    th,
    td {
        width: 30%;
        padding: 8px;

        text-align: left;
    }

    th:first-child,
    td:first-child {
        width: 300px;
    }

    td {
        // text-align: center;
    }
}