@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Varela+Round&display=swap');

:root {
  --pg_primary: #14328c;
  --pg_primary__p: #2470c2;
  --pg_secondary: #f6f6f6;
  --pg_light_grey: #d6d6d6;
  --pg_light_blue: #14328c;
  --pg_sky_blue: #acd4ff;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden !important;
  font-family: Muli-Light, sans-serif;
  letter-spacing: 1px;
}

b {
  font-weight: bold !important;
}

/* overriding bs */

/* progess bar color */
.bg-progress_bar {
  background-color: #14328c !important;
}

.bg-progress_bar__patient {
  background-color: #2470c2 !important;
}

/* progess bar color end */
.text-justify {
  text-align: justify !important;
}

.bgc-primary {
  background: var(--pg_primary) !important;
}

.bgc-primary__p {
  background: var(--pg_primary__p) !important;
}

.bgc-secondary {
  background: var(--pg_secondary) !important;
}

.bgc-success {
  background-color: #0f9a35 !important;
}

.bg-sky-blue {
  background: #acd4ff !important;
}

.bg-grey {
  background-color: var(--pg_light_grey) !important;
}

.bg-grey-1 {
  background-color: #fafafa !important;
}

.bg-grey-2 {
  background-color: #e3e5ee !important;
}

.textc-primary {
  color: var(--pg_primary) !important;
}

.textc-primary__p {
  color: var(--pg_primary__p) !important;
}

.blurry-grey {
  color: #d6d6d6 !important;
}

.border {
  border: 1px solid #000;
}

.btn {
  position: relative;
  overflow: hidden;
  outline: none;
  border: none;
  border-radius: 4px !important;
  font-weight: 900;
  font-family: Avenir-Lightt, sans-serif;
  font-size: 17px;
  line-height: 32px;
  min-height: 50px !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease-in-out;
}

.btn-primary {
  background-color: var(--pg_primary) !important;
  color: #fff;
}

.btn-primary:hover {
  background-color: #0b1d53 !important;
}

.btn-primary__p {
  background-color: #2470c2 !important;
  color: #fff;
}

.btn-primary__p:hover {
  background-color: #09396c !important;
  color: #fff;
}

.btn-light {
  background-color: #fff;
  border: 2px solid #fff;
  color: var(--pg_primary) !important;
}

.btn-light:hover {
  background-color: var(--pg_primary) !important;
  color: #fff !important;
}

.btn-light__p {
  background-color: #fff;
  border: 2px solid #fff;
  color: var(--pg_primary__p) !important;
}

.btn-light__p:hover {
  background-color: var(--pg_primary__p) !important;
  color: #fff !important;
}

.btn-secondary {
  color: var(--pg_primary) !important;
  background-color: var(--pg_sky_blue) !important;
}

.btn-secondary:hover {
  background-color: #acd4ffd0 !important;
}

.btn-outline {
  color: var(--pg_primary) !important;
  border: 2px solid var(--pg_primary);
}

.btn-outline:hover {
  background-color: var(--pg_primary) !important;
  color: #fff !important;
}

.btn-outline__p {
  color: var(--pg_primary__p) !important;
  border: 2px solid var(--pg_primary__p);
}

.btn-outline__p:hover {
  background-color: var(--pg_primary__p) !important;
  color: #fff !important;
}

.btn-outline__invert {
  color: #fff !important;
  background-color: var(--pg_primary) !important;
  border: 2px solid #fff;
}

.btn-outline__invert:hover {
  color: var(--pg_primary) !important;
  background-color: #fff !important;
}

/* .btn:hover{
  background-color: #1a237e !important;
} */

a {
  color: #14328c;
}

.btn:disabled {
  background-color: var(--pg_light_grey) !important;
}

.btn:focus {
  box-shadow: unset !important;
}

.alert {
  border-radius: 8px !important;
}

.cursor-grab {
  cursor: grab;
}

.user-select-none {
  user-select: none;
}

.cursor-pointer,
.cp {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.border-radius {
  border-radius: 4px !important;
}

.transition-all {
  transition: all 0.3s ease-in-out;
}

input::placeholder {
  color: #888888;
  font-family: "Mulish" !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  line-height: 32px !important;
}

input::-moz-placeholder {
  color: #888888;
  font-family: "Mulish" !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  line-height: 20px !important;
}

.loading-bullet {
  color: var(--pg_primary) !important;
}

select {
  padding: 5px 10px;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background: none;
  border-radius: 8px;
  color: #888888;
}

@media print {
  .print-scale * {
    /* transform: scale(1) !important; */
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  .only-print {
    margin-left: 0px;
  }
}

.only-print {
  margin-left: 20px;
  position: absolute;
}

/* button ripple effect */
span.ripple {
  position: absolute;
  /* The absolute position we mentioned earlier */
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

/*-----------------custom css------------------*/

.modal-header {
  justify-content: space-between;
}

.pg-heading .badge {
  padding: 0px !important;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 21px;
  margin-left: 12px;
  vertical-align: middle;
}

@media (min-width: 769px) {
  .pg-heading~.btn-actions {
    position: absolute;
    bottom: 0px;
  }
}

.text-right {
  text-align: right;
}

.btn-res {
  position: inherit !important;
}

.new-tag {
  margin-right: 8px;
}

.p-left {
  padding-left: 5%;
}

.report-table thead th:nth-child(1) {
  padding-left: 68px;
}

.report-table tbody tr td:last-child {
  text-align: right;
}

.custom-width {
  width: 48%;
}

.bg-circle-green {
  background: #4a9b36 !important;
}

.move-left {
  margin-left: 25px !important;
}

.clone-table {
  background-color: #2470c2;
  margin-left: 0px;
  padding: 16px 0px;
  border-radius: 10px 10px 0px 0px;
}

.table-column-clone p {
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0px;
}

.table-column-clone span img {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.table-column-clone h5 {
  color: #ffff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
}

.table-column-clone div:first-child h5 {
  padding-left: 74px;
}

/* toastr customization */
.go2294718771 {
  word-break: break-word;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 8px;
}

.br-none {
  border-radius: 0 !important;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

@media only screen and (max-width: 574px) {
  .past-report-btn {
    font-weight: 700 !important;
    font-size: 10px !important;
    margin-left: 0px !important;
    letter-spacing: 0.5px !important;
  }

  .past-report-table {
    font-size: 13px;
  }
}

@media screen and (min-width: 1024px) {
  .overflow-x-lg-hidden {
    overflow-x: hidden;
  }
}

.dateFld {
  padding: 15px;
  width: 100%;
  outline: none;
  border: 3px solid rgb(172, 212, 255) !important;
  border-radius: 4px;
  height: 50px;
  transition: all 0.3s ease-in-out 0s;
}

.dateFld.error {
  border: 2px solid rgb(255, 0, 0) !important;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker,
.react-datepicker select:focus {
  /* border: 2px solid #007bff; */
  /* box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); */
}

.react-datepicker__triangle {
  /* transform: rotate(45deg); */
  left: 50px !important;
}

.p_addPatient {
  margin-top: 36px !important;
  margin-bottom: 16px !important;
}

.p_addPatientE {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}


.bg-privacy-grey {
  background-color: #f2f2f2;
}

.privacy-table {
  border-collapse: collapse;
  width: 100%;
}

.privacy-table th,
.privacy-table td {
  /* border: 1px solid black; */
  /* padding: 8px; */
  vertical-align: top;
  /* Align content to the top */
}

.privacy-table th {
  background-color: #f2f2f2;
  /* Gray background for header */
}

.password-eye-icon:focus,
.password-eye-icon:focus {
  border: 2px solid #c7cbd2;
}

.pg-select__new[aria-haspopup="listbox"]:focus {
  outline: none !important;
  border: 2px solid #007bff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

@media only screen and (max-width: 1300px) {
  .mult-patient-btn {
    margin-top: 0.5rem;
    margin-left: 0 !important;
  }

}

@media only screen and (min-width: 1230px) and (max-width: 1300px) {
  .add-new-patient-btn {
    margin-top: 0.4rem;
    margin-right: 0.5rem !important;
  }

  .mult-patient-btn {
    margin-top: 0.4rem;
  }
}