.r-item__container {
    position: relative;
    height: 500px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    padding-right: 4px;

    /* For WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
        // border: 2px solid #e0e0e0;
    }

}


.scroll-shadow__patient,
.scroll-shadow {
    position: relative;
    left: 0;
    top: -45px;
    width: 100%;
    height: 30px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .5) 40%, rgba(255, 255, 255, .6) 60%, rgba(255, 255, 255, 1));
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.scroll-shadow__patient {
    top: -26px;
    height: 30px;
}